<template>
  <div
    style="width: 100% !important; display: flex; flex-direction: row; min-height: 100%; height: 100vh; overflow-y: hidden">
    <!----------------------->
    <!-- LEFT MENU         -->
    <!----------------------->
    <div v-if="!isMobile && !comparar" class="pa-0" style="height:100%; width: 300px; min-height: 100%;">
      <!-- Vertical ad -->

      <!--                                     Problems Square -->
      <v-card
        v-if="$root.currentUser === null || !$root.currentUser.premium"
        class="my-2 rounded-lg mx-auto"
              width="300" flat color="transparent">

        <v-card
          :key="`adsense_ad_vertical_${selectedAd}`"
          class="my-2 rounded-lg"
          height="250" style="z-index: 99"
          width="300" flat color="transparent">

          <adsense ad-slot="6844584728"
                   style="display:inline-block;width:300px;height:250px;z-index: 99"
          ></adsense>



        </v-card>
      </v-card>


    </div>


    <!----------------------->
    <!--- PDF VIEWER       -->
    <!----------------------->
    <div style="height: 100vh; display: flex; flex-direction: column; min-height: 100%; flex: 1 !important;"
         class="justify-center text-center">
      <!----------------------->
      <!------- TOP BAR ------->
      <!----------------------->
      <div class="d-flex align-center white--text pr-4" style="background-color: rgb(50,54,57); height: 50px">
<!--        <v-btn-->
<!--          @click="$router.push({path: '/selectivitat/' + $route.params.subject_name + '?tab=2'})"-->
<!--          icon dark class="ml-3">-->
<!--          <v-icon>{{ mdiChevronLeft() }}</v-icon>-->
<!--        </v-btn>-->
        <img align="center" alt="Examenselectivitat" class="icon ml-2" height="42"
             src="https://examenselectivitat.cat:3000/api/images/other/white_logo.png"
             style="display: block; height: auto; margin: 0 auto; border: 0; width: 42px !important;" width="42"/>

        <h1 style="font-size: 24px" class="font-weight-medium font-weight-bold white--text ml-3"
            :class="this.$vuetify.breakpoint.mdAndDown ? 'caption' : 'title' "
        >
          {{ $route.params.subject_name }} {{ $route.params.month }} {{ $route.params.year }}
          <span class="font-weight-regular ml-1">{{
              $route.params.locale === 'Enunciat' ? 'Enunciat' : 'Solució'
            }}</span>
        </h1>

        <v-spacer></v-spacer>

          <v-select
            v-model="selectedYear"
            :items="years"
            @change="goToChange"
            single-line
            style="max-width: 150px"
            class="mr-2"
            solo
            dense
            hide-details
            flat
          ></v-select>

          <v-select
            v-model="month"
            :items="mesos"
            @change="goToChange"
            single-line
            style="max-width: 150px"
            class="mr-2"
            solo
            dense
            hide-details
            flat
          ></v-select>
          <v-select
            v-model="type"
            :items="['Enunciat', 'Solucio']"
            @change="goToChange"
            single-line
            style="max-width: 150px"
            solo
            dense
            hide-details
            flat
          ></v-select>

        <v-spacer></v-spacer>

<!--        <v-chip v-if="!comparar" @click="goToChangeType"-->
<!--                outlined dark style="border: thin solid white"-->
<!--                class="text-none rounded-sm font-weight-medium px-4">-->
<!--          Veure-->
<!--          {{ type === 'Enunciat' ? 'la solució' : 'l\'enunciat' }}-->
<!--        </v-chip>-->

        <v-chip v-if="!$vuetify.breakpoint.xs" @click="compararPdfs()" depressed
                color="primary"
                class="text-none rounded-sm ml-2 font-weight-medium">
          <span v-if="!comparar">Enunciat i solució</span>
          <span v-else>Deixar de comparar</span>
        </v-chip>
      </div>

      <!----------------------->
      <!------ PDF VIEWER ----->
      <!----------------------->
      <div
        v-if="subjectInfo"
        style="flex: 1 !important; height: 100%">
        <object
          :data="`https://examenselectivitat.cat:3000/api/pdfs/${subjectInfo.subject_path}/${$route.params.year}/${$route.params.month}/${$route.params.locale}`"
          type="application/pdf"
          height="100%"
          style="background: #FFFFFF; height: 100%; min-height: 100%"
          :width="comparar ? '50%' : '100%'"
        >
        </object>

        <object
          v-if="comparar"
          :data="`https://examenselectivitat.cat:3000/api/pdfs/${subjectInfo.subject_path}/${$route.params.year}/${$route.params.month}/Solucio`"
          type="application/pdf"
          height="100%"
          style="background: #FFFFFF; min-height: 100%; height: 100%"
          :width="comparar ? '50%' : '100%'"
        >
        </object>
      </div>
    </div>
  </div>
</template>

<script>

import Subject from "../subject/Subject";
import {mdiChevronLeft} from "/src/assets/mdi.json";
import Adsense from "../../../components/anuncis/Adsense.vue";

const array = []
export default {
  name: 'PracticarPDF',
  components: {
    Adsense
  },
  mounted() {
    setInterval(() => {
      this.selectedAd++;
    }, 1000*30)
  },
  metaInfo() {
    return {
      title: this.title
    }
  },
  data() {
    return {
      assig: null,
      tema: null,
      pdfSrc: [],
      loading: false,
      subtemesMovement: false,
      comparar: false,
      pdf: null,
      subjectInfo: null,
      subject: null,
      type: this.$route.params.locale,
      month: this.$route.params.month,
      mesos: ['Juny', 'Setembre'],
      years: [2024, 2023, 2022, 2021, 2020, 2019, 2018, 2017, 2016, 2015, 2014, 2013, 2012, 2011, 2010, 2009, 2008, 2007, 2006, 2005, 2004, 2003, 2002, 2001, 2000],
      selectedYear: parseInt(this.$route.params.year),
      selectedAd: 0,
    }
  },
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.name === 'sm' || this.$vuetify.breakpoint.name === 'xs'
    },
    title() {
      return 'Examen ' + this.deApostrof(this.$route.params.subject_name) + " " + this.$route.params.month + " "
        + this.$route.params.year + " " + (this.$route.params.locale === 'Enunciat' ? 'Enunciat' : 'Solució');
    },
    popularTemes() {
      if (this.subject && this.subject.examsPerTopic) {
        let sorted = this.subject.examsPerTopic.slice();
        sorted = sorted.sort((a, b) => a.trends > b.trends ? -1 : 1);
        let num = 3;
        if (this.$vuetify.breakpoint.sm) num = 2;
        return sorted.slice(0, num)
      }
    },
  },
  methods: {
    mdiChevronLeft() {
      return mdiChevronLeft
    },
    deApostrof(assig) {
      const lletra = assig.charAt(0).toLowerCase()
      return lletra === 'a' || lletra === 'e' || lletra === 'i' || lletra === 'o' || lletra === 'u' || lletra === 'h' ? "d'" + assig : 'de ' + assig
    },
    fetchSubjectInfo: async function () {
      const subject_name = this.$route.params.subject_name

      const {data} = await this.axios.get(`/subject-info/${subject_name}`)
      this.subjectInfo = data
    },
    scrollPosition() {
      if (document.documentElement.scrollTop > 80) this.subtemesMovement = true
      else this.subtemesMovement = false
    },
    goTo: function (row) {
      this.$router.push({path: `${row}`})
    },
    goToChange: function () {
      this.$router.push({path: `/selectivitat/${this.$route.params.subject_name}/pdf/${this.selectedYear}/${this.month}/${this.type}`})
    },
    goBack() {
      this.$router.push({path: `/selectivitat/${this.$route.params.subject_name}`})
    },
    compararPdfs() {
      this.comparar = !this.comparar
      if (this.comparar) {
        this.type = 'Enunciat';
      } else {
        this.type = this.$route.params.locale
      }
    }
  },
  watch: {
    '$route.path': {
      immediate: true,
      async handler() {
        this.type = this.$route.params.locale;
        await this.fetchSubjectInfo();
        // await this.getPdf()
      }
    },
    '$route.params': {
      immediate: true,
      async handler() {
        try {
          this.loading = true;
          const subject_name = this.$route.params.subject_name;
          this.subject = await Subject.build(subject_name);
        } finally {
          this.loading = false;
        }

      }
    },
  }
}
</script>
